import { Link } from "react-router-dom";
import Card from "../Components/Card";
import IconGrid from "../IconGrid";

function Apps() {
  return (
    <Card heading="Apps">
      <IconGrid>
        <Link to="./wsreg">
          <i className="ri-survey-fill"></i>
          <label>Workshop-Registrierung</label>
        </Link>
        <Link to="." className="inactive">
          <i className="ri-team-fill"></i>
          <label>Anmeldung</label>
        </Link>
        <Link to="." className="inactive">
          <i className="ri-links-fill"></i>
          <label>Link Shortener</label>
        </Link>
        <Link to="." className="inactive">
          <i className="ri-chat-poll-fill"></i>
          <label>Umfragen</label>
        </Link>
        <Link to="." className="inactive">
          <i className="ri-bank-fill"></i>
          <label>Freizeit- Kalkulation</label>
        </Link>
        <Link to="." className="inactive">
          <i className="ri-folder-download-fill"></i>
          <label>Datei- Download</label>
        </Link>
        <Link to="." className="inactive">
          <i className="ri-image-fill"></i>
          <label>Galerie</label>
        </Link>
        <Link to="." className="inactive">
          <i className="ri-sword-fill"></i>
          <label>Turnier- Manager</label>
        </Link>
      </IconGrid>
    </Card>
  );
}

export default Apps;