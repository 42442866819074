
export interface CardParameter {
    children: any, //HTML Elements
}

function IconGrid(props : CardParameter) {

    return (
        <div className={"icon_grid"}>
            {props.children}
            <div></div>
        </div>);

}
export default IconGrid;