export interface EvJuSpaceWorkshopEvent {
    _id?: string,
    title: string,
    creator: string,
    registrationStart: Date|undefined,
    phases: Array<EvJuSpaceWorkshopPhase>,
}

export interface EvJuSpaceWorkshopPhase {
    _id?: string,
    title: string,
    workshops: Array<EvJuSpaceWorkshop>,
}

export interface EvJuSpaceWorkshop {
    _id?: string,
    title: string,
    maxNumberOfParticipants: number,
    participants: Array<string>,
}

export async function saveWSREGEvent(event: EvJuSpaceWorkshopEvent) {
    const response = await fetch(process.env.REACT_APP_WSREG_SERVICE + "wsreg/event/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            event: event,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}

export async function saveWSREGRegistration(eventId: string, name: string, phase: Number, workshop: Number): Promise<boolean> {
    const response = await fetch(process.env.REACT_APP_WSREG_SERVICE + "wsreg/registration/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            event: eventId,
            name: name,
            phase: phase,
            workshop: workshop,
        }),
    })

    return response.ok
}

export async function switchWSREGRegistration(eventId: string, name: string, phase: Number, newWorkshop: Number, oldWorkshop: Number): Promise<boolean> {
    const response = await fetch(process.env.REACT_APP_WSREG_SERVICE + "wsreg/switchregistration/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            event: eventId,
            name: name,
            phase: phase,
            newWorkshop: newWorkshop,
            oldWorkshop: oldWorkshop,
        }),
    })

    return response.ok
}

export async function updateWSREGRegistrationTime(eventId: string, newDate: Date): Promise<boolean> {
    const response = await fetch(process.env.REACT_APP_WSREG_SERVICE + "wsreg/updateTime/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            eventId: eventId,
            newDate: newDate,
        }),
    })

    return response.ok
}

export async function getWSRegEvents():Promise<Array<EvJuSpaceWorkshopEvent>> {
    const response = await fetch(process.env.REACT_APP_WSREG_SERVICE + "wsreg/events/", {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function getWSRegEvent(id: string):Promise<EvJuSpaceWorkshopEvent> {
    const response = await fetch(process.env.REACT_APP_WSREG_SERVICE + "wsreg/event/" + id, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}