import { useState } from "react";
import Card from "../../Components/Card";
import EvjuSpaceOverlay from "../../Components/Overlay";
import EvjuSpaceButton from "../../Components/FormElements/EvjuSpaceButton";
import EvjuSpaceInput from "../../Components/FormElements/EvjuSpaceInput";
import { EvJuSpaceWorkshop, EvJuSpaceWorkshopEvent, EvJuSpaceWorkshopPhase, getWSRegEvents, saveWSREGEvent, updateWSREGRegistrationTime } from "../../../connectors/WorkshopRegistration";
import EvjuSpaceDateTime from "../../Components/FormElements/EvjuSpaceDateTime";
import { useLoaderData } from "react-router-dom";
import IndexCard from "../../Components/IndexCard";
import { dateToString } from "../../../Helper/DateFunctions";

function WorkshopRegistrationAdmin() {

  let loadedEvents : any = useLoaderData();

  const [displayedEvents, setDisplayedEvents] = useState<Array<EvJuSpaceWorkshopEvent>>(loadedEvents)
  const [workshopEvent, setWorkshopEvent] = useState<EvJuSpaceWorkshopEvent>({title: "", creator: "", registrationStart: undefined, phases: []});
  const [addWorkshopStep, setAddWorkshopStep] = useState<number>(0)
  const [registrationStart, setRegistrationStart] = useState<Date>(new Date())
  const [editRegistrationStart, setEditRegistrationStart] = useState<boolean>(false)
  const [eventName, setEventName] = useState<string>("")
  const [numberOfPhases, setNumberOfPhases] = useState<number>(0)
  const [phaseCurrentlyEdited, setPhaseCurrentlyEdited] = useState<number>(0)
  const [phaseName, setPhaseName] = useState<string>("")
  const [numberOfWorkshops, setNumberOfWorkshops] = useState<number>(0)
  const [workshops, setWorkshops] = useState<Array<EvJuSpaceWorkshop>>([])

  function openAddOverlay() {
    setAddWorkshopStep(1)
  }

  function createPhases() {
    let phases: Array<EvJuSpaceWorkshopPhase> = new Array();
    for(let i = 0; i < numberOfPhases; i++) {
      phases[i] = {
        title: "",
        workshops: [],
      }
    }
    let newWsE: EvJuSpaceWorkshopEvent = {
      title: eventName,
      creator: "",
      registrationStart: registrationStart,
      phases: phases,
    }
    setWorkshopEvent(newWsE)
    setAddWorkshopStep(addWorkshopStep +1)
  }

  function createWorkshops() {
    let newWorkshops: Array<EvJuSpaceWorkshop> = new Array();
    for(let i = 0; i < numberOfWorkshops; i++) {
      newWorkshops[i] = {
        title: "",
        maxNumberOfParticipants: 0,
        participants: [],
      }
    }
    setWorkshops(newWorkshops)
    setAddWorkshopStep(addWorkshopStep +1)
  }

  function saveWorkshopNames(i: number, v: string) {
    let newWorkshops = workshops
    newWorkshops[i].title = v
    setWorkshops(newWorkshops)
  }

  function saveWorkshopNumbers(i: number, v: number) {
    let newWorkshops = workshops
    newWorkshops[i].maxNumberOfParticipants = v
    setWorkshops(workshops)
  }

  async function saveWorkshops() {
    let newWorkshopEvent = workshopEvent
    newWorkshopEvent.phases[phaseCurrentlyEdited].title = phaseName
    newWorkshopEvent.phases[phaseCurrentlyEdited].workshops = workshops
    setWorkshopEvent(newWorkshopEvent)

    if(phaseCurrentlyEdited < (numberOfPhases - 1)) {
      setPhaseCurrentlyEdited(phaseCurrentlyEdited+1)
      setPhaseName("")
      setNumberOfWorkshops(0)
      setAddWorkshopStep(2)
    } else {

      saveWSREGEvent(workshopEvent)
      setDisplayedEvents(await getWSRegEvents())

      setAddWorkshopStep(4)
    }
  }

  function closeOverlay() {
    setAddWorkshopStep(0)

    setWorkshopEvent({title: "", creator: "", registrationStart: undefined, phases: []})
    setRegistrationStart(new Date())
    setEventName("")
    setNumberOfPhases(0)
    setPhaseCurrentlyEdited(0)
    setPhaseName("")
    setNumberOfWorkshops(0)
    setWorkshops([])
  }

  function openRegistrationStartOverlay(eventId: String|undefined) {
    const event = displayedEvents.filter((e) => {
      return e._id === eventId
    })
    if(event[0].registrationStart) {
      setRegistrationStart(new Date(event[0].registrationStart))
      setWorkshopEvent(event[0])
      setAddWorkshopStep(100)
    }
  }

  async function saveEditedRegistrationStartDate() {
    let r = false
    if(workshopEvent._id) {
      r = await updateWSREGRegistrationTime(workshopEvent._id, registrationStart)
      setDisplayedEvents(await getWSRegEvents())
    }
    if(!r) {
      alert("Das Speichern hat nicht funktioniert. Bitte versuch es später erneut.")
    }
    closeOverlay()
  }

  function displayRegistrations(event: EvJuSpaceWorkshopEvent, p: number, w: number) {
    setWorkshopEvent(event)
    setNumberOfPhases(p)
    setNumberOfWorkshops(w)
    setAddWorkshopStep(101)
  }

  return (
    <>
      <Card heading="Workshop Registrierung" addFn={openAddOverlay}>
        {displayedEvents.length > 0? displayedEvents.map((event: EvJuSpaceWorkshopEvent) => {
          return <IndexCard headerBox={event.title}>
            <h2>Anmeldestart</h2>
            {!editRegistrationStart ? <p>
              {event.registrationStart? dateToString(new Date(event.registrationStart)): ""}
              <i className="ri-edit-2-line" onClick={() => openRegistrationStartOverlay(event._id)}></i>
              </p> : <EvjuSpaceDateTime onChange={() => {}} label={"Ab wann soll die Anmeldung zu Workshops möglich sein?"} />}
            <h2>Anmeldelink</h2>
            <a href={"/apps/wsreg/" + event._id}>{"https://evju.space/apps/wsreg/" + event._id}</a>
            {event.phases.map((phase, p) => {
              return <>
                <h2>{phase.title}</h2>
                {phase.workshops.map((workshop, w) => {
                  return <div className="wsreg_workshop_row" onClick={() => {displayRegistrations(event, p, w)}}>
                      {workshop.title}
                      <div className="wsreg_workshop_numbers">
                        {workshop.participants.length} / {workshop.maxNumberOfParticipants}
                      </div>
                    </div>
                })}
              </>
            })}
          </IndexCard>
        })
        : <p>Du hast bisher noch keine Workshops erstellt</p>}
      </Card>
      <EvjuSpaceOverlay isOpen={addWorkshopStep === 1} onClose={closeOverlay}>
        <h2>Assistent zur Einrichtung eines Workshops</h2>
        <EvjuSpaceInput label="Wie ist der Name des Events, für das du Workshops planst?" type="text" value={eventName} onChange={(s: string) => {setEventName(s)}} />
        <EvjuSpaceInput label="Wie viele Workshopphasen soll es geben?" type="number" value={numberOfPhases} onChange={(n: number) => {setNumberOfPhases(n)}} />
        <EvjuSpaceDateTime onChange={setRegistrationStart} value={registrationStart} label={"Ab wann soll die Anmeldung zu Workshops möglich sein?"} />
        <br /><br /><br /><br />
        <EvjuSpaceButton class="next_button" text="Weiter" onClick={createPhases}/>
      </EvjuSpaceOverlay>

      <EvjuSpaceOverlay isOpen={addWorkshopStep === 2} onClose={closeOverlay}>
        <h2>Assistent zur Einrichtung eines Workshops</h2>
        <EvjuSpaceInput label={"Wie soll die " + (phaseCurrentlyEdited+1) + ". Workshopphase heißen?"} type="text" value={phaseName} onChange={(s: string) => {setPhaseName(s)}} />
        <EvjuSpaceInput label={"Wie viele Workshops soll es in der " + (phaseCurrentlyEdited+1) + ". Workshopphase geben?"} type="number" value={numberOfWorkshops} onChange={(n: number) => {setNumberOfWorkshops(n)}} />
        <br /><br /><br /><br />
        <EvjuSpaceButton class="next_button" text="Weiter" onClick={createWorkshops}/>
      </EvjuSpaceOverlay>

      <EvjuSpaceOverlay isOpen={addWorkshopStep === 3} onClose={closeOverlay}>
        <h2>Assistent zur Einrichtung eines Workshops</h2>
        {workshops.map((item, i) => {
          return <>
            <EvjuSpaceInput label={"Wie soll der " + (i+1) + ". Workshop heißen?"} onChange={(v: string) => {saveWorkshopNames(i, v)}}/>
            <EvjuSpaceInput label={"Wie viele Teilnehmende darf dieser Workshop maximal haben?"} type="number" onChange={(v: number) => {saveWorkshopNumbers(i, v)}} />
            <br /><br /><br />
          </>
        })}
        <br /><br /><br /><br />
        <EvjuSpaceButton class="next_button" text="Weiter" onClick={saveWorkshops}/>
      </EvjuSpaceOverlay>

      <EvjuSpaceOverlay isOpen={addWorkshopStep === 4} onClose={closeOverlay}>
        <h2>Assistent zur Einrichtung eines Workshops</h2>
        <p>Das Speichern war erfolgreich.</p>
        <p>Die Anmeldung wird zum gewählten Zeitpunkt freigeschaltet.</p>
        <br /><br /><br /><br />
        <EvjuSpaceButton class="next_button" text="Beenden" onClick={closeOverlay}/>
      </EvjuSpaceOverlay>

      <EvjuSpaceOverlay isOpen={addWorkshopStep === 100} onClose={closeOverlay}>
        <h2>Assistent zur Einrichtung eines Workshops</h2>
        <EvjuSpaceDateTime onChange={setRegistrationStart} value={registrationStart} label={"Ab wann soll die Anmeldung zu Workshops möglich sein?"} />
        <br /><br /><br /><br />
        <EvjuSpaceButton class="next_button" text="Speichern" onClick={saveEditedRegistrationStartDate}/>
      </EvjuSpaceOverlay>

      <EvjuSpaceOverlay isOpen={addWorkshopStep === 101} onClose={closeOverlay}>
        <h2>Anmeldungen für den Workshop {workshopEvent?.phases[numberOfPhases]?.workshops[numberOfWorkshops]?.title} ({workshopEvent?.phases[numberOfPhases]?.workshops[numberOfWorkshops]?.participants.length}/{workshopEvent?.phases[numberOfPhases]?.workshops[numberOfWorkshops]?.maxNumberOfParticipants})</h2>
        {workshopEvent?.phases[numberOfPhases]?.workshops[numberOfWorkshops]?.participants.map((p, i) => {
          return <p>{i+1}. {p}</p>
        })}
      </EvjuSpaceOverlay>
    </>
  );
}

export default WorkshopRegistrationAdmin;

export async function workshopRegistrationLoader({ params } : any) {
    let events = await getWSRegEvents();
    return events;
}