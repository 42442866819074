import { Link } from "react-router-dom";
import Card from "../Components/Card";
import IconGrid from "../IconGrid";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";

function Settings() {
    const [userContext, setUserContext] = useContext<any>(UserContext);

    return (
        <>
            <Card heading="Einstellungen">
                <IconGrid>
                    <Link to="./user">
                        <i className="ri-account-circle-fill"></i>
                        <label>Profil</label>
                    </Link>
                    <Link to="./applyToServer">
                        <i className="ri-hard-drive-3-fill"></i>
                        <label>Server beitreten</label>
                    </Link>


                    {userContext.details.role == "admin" ?
                        <Link to="./admin">
                            <i className="ri-admin-fill"></i>
                            <label>Admin- Einstellungen</label>
                        </Link>
                    : "" }
                    <Link to="." className="inactive">
                      <i className="ri-bar-chart-2-fill"></i>
                      <label>Statistiken</label>
                    </Link>
                </IconGrid>
            </Card>
        </>
    );
}
export default Settings;