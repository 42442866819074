import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { UserProvider } from './context/UserContext'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './Layout/Error';
import App from './App';
import Login, { loginLoader } from './Login/Login';
import Register from './Login/Register/Register';
import Dashboard from './Layout/Dashboard/Dashboard';
import Settings from './Layout/Settings/Settings';
import Profile, { ProfileLoader } from './Layout/Settings/Profile';
import Server, { serverLoader } from './Layout/Server/Server';
import Group, { groupLoader } from './Layout/Server/Groups/Group';
import Adminpanel, { adminpanelLoader } from './Layout/Settings/Admin/Adminpanel';
import NewServer from './Layout/Settings/Admin/NewServer';
import ServerSettings, { serverSettingLoader } from './Layout/Server/ServerSettings';
import EventDetails, { eventDetailsLoader } from './Layout/Server/Groups/Events/EventDetails';
import CreateEvent, { createEventLoader } from './Layout/Server/Groups/Events/createEvent';
import CreateAgenda, { agendaLoader } from './Layout/Server/Groups/Events/createAgenda';
import EventSettings, { eventSettingsLoader } from './Layout/Server/Groups/Events/EventSettings';
import EventProtocol, { eventProtocolLoader } from './Layout/Server/Groups/Events/Protocol/EventProtocol';
import GroupSettings, { groupSettingLoader } from './Layout/Server/Groups/GroupSettings';
import ForgotPassword, { forgotPasswordLoader } from './Login/ForgotPassword';
import SetNewPassword, { setNewPasswordLoader } from './Login/SetNewPassword';
import EmailConfirmation, { emailConfirmationLoader } from './Login/EmailConfirmation';
import ApplyToServerSettings from './Layout/Settings/ApplyToServerSettings';
import GroupMembers, { groupMemberLoader } from './Layout/Server/Groups/GroupMembers';
import Bugreport, { bugreportLoader } from './Layout/Bugreport/Bugreport';
import Impressum, { impressumLoader } from './Layout/LegalDoc/Impressum';
import TermsOfUse, { termsOfUseLoader } from './Layout/LegalDoc/TermsOfUse';
import Privacy, { privacyLoader } from './Layout/LegalDoc/Privacy';
import WorkshopRegistrationAdmin, { workshopRegistrationLoader } from './Layout/Apps/WorkshopRegistration/Management';
import Apps from './Layout/Apps/Apps';
import WSRegRegistration, { wsRegRegistrationLoader } from './Layout/Apps/WorkshopRegistration/Registration';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/settings/",
        element: <Settings />,
      },
      {
        path: "/settings/user/",
        element: <Profile />,
        loader: ProfileLoader,
      },
      {
        path: "/settings/applyToServer/",
        element: <ApplyToServerSettings />,
        // loader: applyToServerLoader,
      },
      {
        path: "/settings/admin/",
        element: <Adminpanel />,
        loader: adminpanelLoader,
      },
      {
        path: "/settings/admin/newServer/",
        element: <NewServer />,
      },
      {
        path: "/apps/",
        element: <Apps />
      },
      {
        path: "/apps/wsreg/",
        element: <WorkshopRegistrationAdmin />,
        loader: workshopRegistrationLoader,
      },
      {
        path: "/bugreport/",
        element: <Bugreport />,
        loader: bugreportLoader,
      },
      {
        path: "/server/:id",
        element: <Server />,
        loader: serverLoader,
      },
      {
        path: "/server/:id/settings",
        element: <ServerSettings />,
        loader: serverSettingLoader,
      },
      {
        path: "/group/:id",
        element: <Group />,
        loader: groupLoader,
      },
      {
        path: "/group/:id/members",
        element: <GroupMembers />,
        loader: groupMemberLoader,
      },
      {
        path: "/group/:id/settings",
        element: <GroupSettings />,
        loader: groupSettingLoader,
      },
      {
        path: "/group/:id/createEvent",
        element: <CreateEvent />,
        loader: createEventLoader,
      },
      {
        path: "/group/:groupid/event/:eventid",
        element: <EventDetails />,
        loader: eventDetailsLoader,
      },
      {
        path: "/group/:groupid/event/:eventid/protocol",
        element: <EventProtocol />,
        loader: eventProtocolLoader,
      },
      {
        path: "/group/:groupid/event/:eventid/settings",
        element: <EventSettings />,
        loader: eventSettingsLoader,
      },
      {
        path: "/group/:groupid/event/:eventid/agenda",
        element: <CreateAgenda />,
        loader: agendaLoader,
      },
    ]
  },
  {
    path: "/login/",
    element: <Login />,
    loader: loginLoader,
  },
  {
    path: "forgotPassword/",
    element: <ForgotPassword />,
    loader: forgotPasswordLoader,
  },
  {
    path: "forgotPassword/:mail",
    element: <ForgotPassword />,
    loader: forgotPasswordLoader,
  },{
    path: "setNewPassword/:resetid/:userid",
    element: <SetNewPassword />,
    loader: setNewPasswordLoader,
  },
  {
    path: "emailConfirmation/:userid/:username",
    element: <EmailConfirmation />,
    loader: emailConfirmationLoader,
  },
  {
    path: "/register/",
    element: <Register />
  },
  {
    path: "/impressum",
    element: <Impressum />,
    loader: impressumLoader,
  },
  {
    path: "/termsofuse",
    element: <TermsOfUse />,
    loader: termsOfUseLoader,
  },
  {
    path: "/privacy",
    element: <Privacy />,
    loader: privacyLoader,
  },
  {
    path: "/apps/wsreg/:id",
    element: <WSRegRegistration />,
    loader: wsRegRegistrationLoader,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(

  <React.StrictMode>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </React.StrictMode>

)