import { useLoaderData } from "react-router-dom";
import { EvJuSpaceWorkshop, EvJuSpaceWorkshopEvent, EvJuSpaceWorkshopPhase, getWSRegEvent, saveWSREGRegistration, switchWSREGRegistration } from "../../../connectors/WorkshopRegistration";
import Card from "../../Components/Card";
import Footer from "../../LegalDoc/Footer";
import { useEffect, useState } from "react";
import EvjuSpaceInput from "../../Components/FormElements/EvjuSpaceInput";
import { dateToString } from "../../../Helper/DateFunctions";

function WSRegRegistration() {

    const loadedEvent: any = useLoaderData();

    const [event, setEvent] = useState<EvJuSpaceWorkshopEvent>(loadedEvent)
    const [name, setName] = useState<string>("")
    const [registration, setRegistration] = useState<Array<{phase: Number, workshop: Number}>>([])
    const [now, setNow] = useState<Date>(new Date())

    useEffect(() => {
        const interval = setInterval(async () => {
            setNow(new Date())
            if(event._id) {
                setEvent(await getWSRegEvent(event._id))
            }
        }, 5000)

        return () => {clearInterval(interval)}
    }, [now])

    async function register(phase: Number, workshop: Number) {
        if(!event._id) {
            return
        }
        if(!name) {
            return
        }

        const allreadyRegistered = phaseAllreadyRegistered(phase)
        if(allreadyRegistered) {
            const result = await switchWSREGRegistration(event._id, name, phase, workshop, allreadyRegistered.workshop)
            if(result) {
                setRegistration(registration.filter((e) => {
                    return e.phase !== allreadyRegistered.phase || e.workshop !== allreadyRegistered.workshop
                }))
                const newValue = {phase: phase, workshop: workshop}
                setRegistration(oldArray => [...oldArray, newValue])
            }
        } else {
            const result = await saveWSREGRegistration(event._id, name, phase, workshop)
            if(result) {
                const newValue = {phase: phase, workshop: workshop}
                setRegistration(oldArray => [...oldArray, newValue])
            } else {
                console.log("Das Speichern war nicht erfolgreich")
            }
        }
        
        setEvent(await getWSRegEvent(event._id))
    }

    function isRegistered(p: Number, w: Number) {
        const element = registration.find((e) =>{
            return e.phase === p && e.workshop === w
        })
        if(element) {
            return true
        } else {
            return false
        }
    }

    function phaseAllreadyRegistered(p: Number) {
        const element = registration.find((e) =>{
            return e.phase === p
        })
        return element
    }

    function isOpen() {
        return !event.registrationStart || new Date(event.registrationStart) < now
    }

    return (<>
        <Card heading={"Workshop-Anmeldung"} headerBox={event?.title}  visible_when_loggedout={true}>
            {!isOpen() ?<p className="wsreg_warn">
                Die Anmeldung wird erst zu folgendem Zeitpunkt eröffnet: {event.registrationStart ? dateToString(new Date(event.registrationStart)) : "ERROR"}
            </p> : ""}
            <EvjuSpaceInput label="Wie ist dein Vorname und Nachname?" value={name} onChange={setName} error={isOpen() && name === ""} disabled={registration.length !== 0} />
            {isOpen() && name === "" ? <p className="wsreg_info">
                Bitte gib zuerst deinen Namen ein.
            </p> : ""}
            {isOpen() && name !== "" ? <p className="wsreg_info">
                Bitte wähl drei Workshops aus, an denen du teilnehmen möchtest. <br />
                Pro Workshopphase kannst du einen Workshop auswählen. <br />
                Deine Auswahl wird automatisch gespeichert.
            </p> :""}
            {isOpen() && name !== "" ? <div className="wsreg_workshops">
                {event.phases.map((phase: EvJuSpaceWorkshopPhase, p: Number) => {
                    return <>
                        <h2>{phase.title}</h2>
                        {phase.workshops.map((workshop: EvJuSpaceWorkshop, w: Number) => {
                            return <>
                                <div className={
                                    "wsreg_workshop_row"
                                    + (isRegistered(p, w) ? " wsreg_workshop_registered" : "")
                                    + (!isRegistered(p, w) && workshop.participants.length >= workshop.maxNumberOfParticipants ? " wsreg_workshop_full" : "")
                                    } onClick={() => {
                                        if(!isRegistered(p, w) && workshop.participants.length < workshop.maxNumberOfParticipants && isOpen()) {
                                            register(p, w)
                                        }
                                    }}>
                                    {workshop.title}
                                    <div className="wsreg_workshop_numbers">
                                        {workshop.participants.length} / {workshop.maxNumberOfParticipants}
                                    </div>
                                </div>
                            </>
                        })}
                    </>
                })}
            </div> : ""}
        </Card>
        <Footer />
    </>);
}

export default WSRegRegistration;

export async function wsRegRegistrationLoader({ params } : any) {
    return await getWSRegEvent(params.id);
}