
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { EvjuSpaceServer, getServerList } from "../../connectors/ServerConnector";
import "../style.css";
import { EvjuSpaceUser, getUserFromRefreshToken } from "../../connectors/UserConnector";
import { UserContext } from "../../context/UserContext";
import EvjuSpaceOverlay from "../Components/Overlay";
import ApplyToServer from "../Components/ApplyToServer";

function Sidebar(logout:any) {

    const [userContext, setUserContext] = useContext<any>(UserContext);
    
    const settingsLinkRef = React.useRef<HTMLAnchorElement>(null);
    const [activeNav, setActiveNav] = useState<String>("");
    const [serverList, setServerList] = useState<EvjuSpaceServer[]>([
        {_id: "0", name: "Loading...", groups: []}
    ])
    const [isNewServerOverlayOpen, setIsNewServerOverlayOpen] = useState<boolean>(false)
    // const [user, setUser] = useState<EvjuSpaceUser>()

    useEffect(() => {
        getServerList().then( res => {
            setServerList(res)
        }
        )
    }, [])

    useEffect(() => {
        if(serverList.length == 0) {
            setIsNewServerOverlayOpen(true)
        }
    },  [serverList])
    /*
    const [burgermenu, setBurgermenu] = useState<boolean>(true);

    function toggleBurgermenu() {
        setBurgermenu(!burgermenu);
    }

    
    <div className="sidebar-smartphone-header" ref={burgerref}>
        <i className="bi bi-list" onClick={toggleBurgermenu}></i>
    </div>

    <div id="sidebar-id" className="sidebar" style=
        {burgermenu ? {display: "block"} : {display: "none"}}
    >
    */

    function noServer(): boolean {
        return true
    }

    return (
        <>
            <div id="sidebar-id" className="sidebar">
                <NavLink to="/" className={({ isActive, isPending}) =>
                    isActive
                    ? "sidebarMenuActive"
                    : isPending
                    ? "sidebarMenuPending"
                    : ""
                    }onClick={() => {
                        setActiveNav("dashboard")
                    }}>
                    Dashboard
                </NavLink>
                
                {serverList.map((server, i) => {
                    return <div key={"server"+i}>
                        <NavLink to={"/server/" + server._id} className={({ isActive, isPending}) =>
                            isActive
                            ? "sidebarMenuActive"
                            : isPending
                            ? "sidebarMenuPending"
                            : ""
                            } onClick={() => {
                                setActiveNav(server._id)
                            }} key={"Serverlink" + i}>
                            {server.name}
                        </NavLink>

                        {(activeNav == server._id) ? (
                            <div className="sidebar-submenu">
                                {server.groups?.map((group, j) => {
                                    return <div key={"group"+i+"-"+j}>
                                        <NavLink to={"/group/" + group._id} className={({ isActive, isPending}) =>
                                            isActive
                                            ? "sidebarMenuActive"
                                            : isPending
                                            ? "sidebarMenuPending"
                                            : ""
                                            } onClick={() => {
                                                setActiveNav(server._id)
                                            }} key={"Grouplink" + i + "-" + j}>
                                            {group.name}
                                        </NavLink>
                                    </div>
                                })}
                                
                                
                            </div>
                        ) : " "}
                    </div>
                })}

                <div className="sidebar-bottom">
                    <NavLink to="apps/" className={({ isActive, isPending}) =>
                        isActive
                        ? "sidebarMenuActive"
                        : isPending
                        ? "sidebarMenuPending"
                        : ""
                        } onClick={() => {
                            setActiveNav("apps")
                        }}>
                        Apps
                    </NavLink>

                    {(activeNav == "apps") ? (
                        <div className="sidebar-submenu">
                            <NavLink to="apps/wsreg"  className={({ isActive, isPending}) =>
                                isActive
                                ? "sidebarMenuActive"
                                : isPending
                                ? "sidebarMenuPending"
                                : ""
                                } onClick={() => {
                                    setActiveNav("apps")
                                }}>
                                Workshop-Registrierung
                            </NavLink>
                        </div>
                    ) : " "}

                    <NavLink to="settings/" className={({ isActive, isPending}) =>
                        isActive
                        ? "sidebarMenuActive"
                        : isPending
                        ? "sidebarMenuPending"
                        : ""
                        } onClick={() => {
                            setActiveNav("settings")
                        }}>
                        Einstellungen
                    </NavLink>

                    {(activeNav == "settings") ? (
                        <div className="sidebar-submenu">
                            <NavLink to="settings/user"  className={({ isActive, isPending}) =>
                                isActive
                                ? "sidebarMenuActive"
                                : isPending
                                ? "sidebarMenuPending"
                                : ""
                                } onClick={() => {
                                    setActiveNav("settings")
                                }}>
                                Profil
                            </NavLink>
                            <NavLink to="settings/applyToServer"  className={({ isActive, isPending}) =>
                                isActive
                                ? "sidebarMenuActive"
                                : isPending
                                ? "sidebarMenuPending"
                                : ""
                                } onClick={() => {
                                    setActiveNav("settings")
                                }}>
                                Server beitreten
                            </NavLink>
                            {userContext.details.role == "admin" ?
                            <NavLink to="settings/admin"  className={({ isActive, isPending}) =>
                                isActive
                                ? "sidebarMenuActive"
                                : isPending
                                ? "sidebarMenuPending"
                                : ""
                                } onClick={() => {
                                    setActiveNav("settings")
                                }}>
                                Admin
                            </NavLink> : ""}
                        </div>
                    ) : " "}

                    <a href="#" onClick={logout.logout}>Logout</a>

                    <NavLink to="bugreport/" id="bugreportlink" className={({isActive, isPending}) =>
                        isActive
                        ? "sidebarMenuActive"
                        : isPending
                        ? "sidebarMenuPending"
                        : ""
                        } onClick={() => {
                            setActiveNav("bugreport")
                        }}>
                        Bugreport
                    </NavLink>
                </div>
            </div>

            <EvjuSpaceOverlay isOpen={isNewServerOverlayOpen} onClose={() => {setIsNewServerOverlayOpen(false)}}>
                <>
                    <h1>Du bist noch nicht Mitglied eines Servers.</h1>
                    <p>Guck doch mal in unsere Serverliste. Vielleicht ist ja einer dabei, bei dem du die Mitgliedschaft beantragen möchtest.</p>
                    <ApplyToServer />
                </>
            </EvjuSpaceOverlay>
        </>
    );
}
export default Sidebar;